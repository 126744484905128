@font-face {
  font-family: "Apercu";
  src: url("apercu-light.woff2") format("woff2"),
    url("apercu-light.woff") format("woff"),
    url("apercu-light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Apercu";
  src: url("apercu-regular.woff2") format("woff2"),
    url("apercu-regular.woff") format("woff"),
    url("apercu-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Canela";
  src: url("canela-light.woff2") format("woff2"),
    url("canela-light.woff") format("woff"),
    url("canela-light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Canela";
  src: url("canela-light-italic.woff2") format("woff2"),
    url("canela-light-italic.woff") format("woff"),
    url("canela-light-italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
